import React from "react"

import MuiThemeProvider from "./src/components/mui-theme-provider"

export const wrapRootElement = ({ element }) => {
  return <MuiThemeProvider>{element}</MuiThemeProvider>
}

document.addEventListener("contextmenu", e => {
  if (e.target.tagName === "IMG") {
    e.preventDefault()
    e.stopPropagation()
  }
})
