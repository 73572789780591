import { createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import React from "react"

const MuiThemeProvider = ({ children }) => {
  const theme = createTheme({
    palette: {
      primary: {
        light: "#ffffff",
        main: "#68b7a1",
        dark: "#ccc0ae",
        contrastText: "#000000",
      },
    },
    typography: {
      fontFamily: "Zen Maru Gothic",
    },
    components: {
      MuiTextField: {
        defaultProps: {
          InputLabelProps: {
            shrink: true,
          },
        },
        styleOverrides: {
          root: {
            width: "100%",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          formControl: {
            position: "static",
            transform: "none",
            transition: "none",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            marginTop: 0,
          },
          input: {
            paddingTop: "10px",
            paddingBottom: "8px",
            height: "auto",
          },
          notchedOutline: {
            top: 0,
            legend: {
              display: "none",
            },
          },
          inputMultiline: {
            padding: "0",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: "red",
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: "4px",
          },
        },
      },
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default MuiThemeProvider
